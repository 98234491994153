import $http from '@/utitls/request'
/**
 * 获取指定参数信息
 */
export const GetParameConfig = (data) => {
    return $http.get('/api/ParameConfig/GetParameConfig', { params: data })
}
/**
 * 获取多个指定参数信息
 */
export const GetParameConfigs = (data) => {
    return $http.get('/api/ParameConfig/GetParameConfigs', { params: data })
}
/**
 * 分页获取参数信息
 */
export const GetParameConfigPageList = (data) => {
    return $http.get('/api/ParameConfig/GetParameConfigPageList', { params: data })
}
/**
 * 修改
 */
export const EditParameConfig = (data) => {
    return $http.put('/api/ParameConfig/EditParameConfig', data)
}
